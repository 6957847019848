

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Popover, Transition } from '@headlessui/react'
import { connect } from "react-redux"
import { NavLink, Link } from 'react-router-dom'
import Logo7 from 'assets/img/Logo7.png'
import { useState, userState, Fragment } from 'react'
import loading from 'assets/img/loading.gif'
import DotLoader from "react-spinners/DotLoader"
import { scroll } from "react-scroll";
import LGRO from 'assets/img/LGRO.png';
import ScrollSpy from "react-ui-scrollspy";


const solutions = [
  {
    name: 'Inicio',
    description: '',
    href: '/',
    icon: IconDot,
  },
  {
    name: 'Acerca de',
    description: '',
    href: '/Acerca',
    icon: IconDot,
  },
  {
    name: 'Eventos pasados',
    description: '',
    href: '/Eventospasados',
    icon: IconDot,
  },
  {
    name: 'Colaboradores',
    description: '',
    href: '/Colaboradores',
    icon: IconDot,
  },
  {
    name: 'Patrocinadores',
    description: '',
    href: '/Patrocinadores',
    icon: IconDot,
  },
  {
    name: 'Academia',
    description: '',
    href: '/Academia',
    icon: IconDot,
  },

]

function Navbar() {


  const [loading, setLoading] = useState(true)

  window.onscroll = function () { scrollFunction() }

  function scrollFunction() {
    if (document.getElementById('navbar')) {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById('navbar').classList.add('shadow-navbar');
        document.getElementById('navbar').classList.add('bg-white');
      } else {
        document.getElementById('navbar').classList.remove('shadow-navbar');
        document.getElementById('navbar').classList.remove('bg-white');
      }
    }
  }

  const [open, setOpen] = useState(false)

  return (
    <nav id="navbar" className="w-full py-6 top-0 transition duration-300 ease-in-out bg-white1 z-40 fixed ">
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-12 px-2">
          <Link to='/' className="ml-4 mt-2">
            <img
              src={LGRO}
              width={290}
              height={290}
              className="" />

            <h3 className="text-lg font-medium leading-6 text-white-900"></h3>
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0" data-te-spy="scroll" data-te-target="#scrollspy1">

            {/*
        <button class="peer px-5 py-2 bg-green-600 hover:bg-green-700 text-white">Dropdown</button>
        <div class="hidden peer-hover:flex hover:flex w-[10vw] flex-col bg-white drop-shadow-lg">
            <a class="px-5 py-3 hover:bg-gray-200" href="#">About Us</a>
            <a class="px-5 py-3 hover:bg-gray-200" href="#">Contact Us</a>
            <a class="px-5 py-3 hover:bg-gray-200" href="#">Privacy Policy</a>
        </div>*/}


            <NavLink to='/Acerca' className="text-white inline-flex font-medium leading-6 text-white-900 hover:text-yellow-500 hover:underline hover:underline-offset-4 mx-5 text-2xl peer" data-dropdown-toggle="dropdownHover" data-dropdown-trigger="hover" data-to-scrollspy-id="acercaDe">Acerca de</NavLink>
            <div id="dropdownHover" class="absolute z-10 hidden bg-white divide-y divide-white-100 rounded-lg shadow w-100 mt-0.5 white:bg-gray-700 hidden peer-hover:flex hover:flex ">
              <ul class="py-2 text-sm dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                {/*<li>
              <a href="/Pregunta" class="block px-10 py-2 dark:hover:bg-gray-800 dark:hover:text-white" style={{color:"#3AAAE1"}}>Preguntas frecuentes</a>
      </li>*/}
              </ul>
            </div>

            <NavLink to='/Eventospasados' className="text-white inline-flex font-medium leading-6 text-white-900 hover:text-yellow-500 hover:underline hover:underline-offset-4 mx-5 text-2xl peer" data-dropdown-toggle="dropdownHoverEventos" data-dropdown-trigger="hover" data-to-scrollspy-id="eventosPasados">Eventos pasados</NavLink>
            <div id="dropdownHoverEventos" class="absolute z-10 hidden bg-white divide-y divide-white-100 rounded-lg shadow w-100 mt-0.5 white:bg-gray-700 hidden peer-hover2:flex hover:flex ">
              <ul class="py-2 text-sm dark:text-gray-200" aria-labelledby="dropdownHoverButtonEventos">
                <li>
                  <a href="#" class="block px-10 py-2 dark:hover:bg-gray-800 dark:hover:text-white" style={{ color: "#DB0C81" }}>2022</a>
                </li>
                <li>
                  <a href="#" class="block px-10 py-2 dark:hover:bg-gray-800 dark:hover:text-white" style={{ color: "#3AAAE1" }}>2021</a>
                </li>
              </ul>
            </div>
            <NavLink to='/Colaboradores' className="text-white inline-flex font-medium leading-6 text-white-900 hover:text-yellow-500 hover:underline hover:underline-offset-4 mx-5 text-2xl" data-to-scrollspy-id="colaboradores">Colaboradores</NavLink>
            <NavLink to='/Patrocinadores' className="text-white inline-flex font-medium leading-6 text-white-900 hover:text-yellow-500 hover:underline hover:underline-offset-4 mx-5 text-2xl" data-to-scrollspy-id="patrocinadores">Patrocinadores</NavLink>
            <NavLink to='/Academia' className="text-white inline-flex font-medium leading-6 text-white-900 hover:text-yellow-500 hover:underline hover:underline-offset-4 mx-5 text-2xl" data-to-scrollspy-id="patrocinadores">Academia</NavLink>



          </div>
        </div>
        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap py-2 items-center justify-between sm:flex-nowrap md:px-12 px-2 ">
          <Link to='/' className="ml-4 mt-2">
            <img
              src={LGRO}
              width={120}
              height={120}
              className="" />

            <h3 className="text-lg font-medium leading-6 text-gray-900"></h3>
          </Link>
          <div className=" h-10">
            <div className="">
              <Popover className="relative">
                {/* className="relative" */}
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`
                ${open ? '' : 'text-opacity-90'}
                aitems-center px-1 py-1 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >

                      {/*
                className={`
                ${open ? '' : 'text-opacity-90'}
                -translate-x-full group inline-flex items-center rounded-md bg-orange5 px-4 py-3 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
              */}
                      {/* <span>Menu</span> */}

                      <svg className={`${open ? '' : 'text-opacity-70'}
                 h-9 w-9 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>

                      {/* <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              /> */}
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -left-24 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl max-height: 400px; overflow-y: auto;">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                            {solutions.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                  <item.icon aria-hidden="true" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                          <div className="bg-gray-50 p-4">
                            {/*<a
                      href="##"
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">
                          Documentation
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500">
                        Start integrating products and tools
                      </span>
                    </a>*/}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>

            <button className=''>
              <i class='bx bx-menu text-8xl'></i>
            </button>

          </div>
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {

})(Navbar)

function IconDot() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="15" fill="#FF6384" />
    </svg>
  );
}


function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  )
}
// 3:20:23

// <NavLink to= '/Registro' className="text-lg inline-flex font-medium leading-6 text-gray-900 hover:text-orange-500 hover:underline hover:underline-offset-4 mx-5">Registro</NavLink>
// <NavLink to= '/Convocatoria'className="text-lg inline-flex font-medium leading-6 text-gray-900 hover:text-orange-500 hover:underline hover:underline-offset-4 mx-5">Convocatoria</NavLink>

/*

<button
            type="button"
            to = '/'
            className="ml-12 relative inline-flex items-center rounded-md border border-transparent bg-orange-button px-3 py-2 text-sm font-bold text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
            Iniciar Sesion
            
            <DotLoader loading={loading} size = {15} color = '#c63782'/>
          </button>
*/



/*
function MyComponent() {
  React.useEffect(() => {
    function handleResize() {

      }

    window.addEventListener('resize', handleResize)
  })
  return <div>w00t!</div>
}
*/