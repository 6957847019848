/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import Ariel1 from 'assets/img/Ariel1.jpg'
import Felipe2 from 'assets/img/Felipe2.jpg'
import Ramon1 from 'assets/img/Ramon1.jpg'
import ScrollSpy from "react-ui-scrollspy";

const people = [
  {
    name: <span style={{ color: '#3AAAE1' }}>Ing. Ramón Omar Córdova Muñoz</span>,
    role: 'Director General de ENMICE. Director General y de Tecnología en BID360 Aeroespacial',
    imageUrl:Ramon1,
    twitterUrl: 'https://twitter.com/ramonomarcm',
    linkedinUrl: 'https://mx.linkedin.com/in/ram%C3%B3n-omar-c%C3%B3rdova-mu%C3%B1oz-208232a5',
  },

  {
    name: <span style={{ color: '#3AAAE1' }}>Ing. Carlos Felipe Ávila Gómez</span>,
    role: 'Director de Operaciones y Estrategia de ENMICE',

    imageUrl: Felipe2,
    twitterUrl: '#',
    linkedinUrl: '#',
  },

  {
    name: <span style={{ color: '#3AAAE1' }}>Walter Adrián Ahrens Castro</span>,
    role: 'Director de Operaciones de Lanzamiento de ENMICE, Prefecto de Tripoli Rocketry Association Inc.',
    imageUrl:
      'https://media.licdn.com/dms/image/C5603AQHOByjxh05B3Q/profile-displayphoto-shrink_800_800/0/1659721311910?e=2147483647&v=beta&t=TBYEz3pBj4bcKDOMydZB8m1ZrHDb73Pt5Vumbu-75nI',
    twitterUrl: '#',
    linkedinUrl: 'https://mx.linkedin.com/in/walterahrensc?original_referer=https%3A%2F%2Fwww.google.com%2F',
  },

  {
    name:<span style={{ color: '#3AAAE1' }}>Mtro. Ariel Hiram Gómez López</span>,
    role: 'Big Data Engineer at Tiger Analytics Monterrey, Nuevo León, México',
    imageUrl: Ariel1,
    twitterUrl: '#',
    linkedinUrl: 'https://mx.linkedin.com/in/ahgl',
  },

  /*
  {
      name: 'Ing. Pablo Carranza Plata',
      role: 'Director de Ingeniería y Certificación de Normas de la Agencia Federal de Aviación Civil (AFAC).',
      imageUrl:
        'https://www.gob.mx/cms/uploads/structure/main_image/4994/Pablo_Carranza.jpg',
      twitterUrl: '#',
      linkedinUrl: '#',
  },

  {
      name: 'Ing. Luis G. Lizcano',
      role: 'Director Ejecutivo de la Federación Mexicana de la Industria Aeroespacial (FEMIA).',
      imageUrl:
        'https://d2n4wb9orp1vta.cloudfront.net/cms/brand/MMS-Mex/2020-MMS-Mex/lizcano.jpg',
      twitterUrl: '#',
      linkedinUrl: '#',
  },
  */
  // More people...
]

export default function Team() {
  return (
    <ScrollSpy>
    <div className="bg-black" id="eventosPasados">
      <div className="mx-auto lg:mx-12 max-w-full py-12 px-4 sm:px-6 lg:px-8 lg:py-18">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-white text-center"><span style={{ color: '#3AAAE1' }}>Conócenos</span></h2>
            <p className="text-xl text-white text-center">
              Inspirados por el desarrollo de tecnología espacial y el talento mexicano, ENMICE fue fundado en el 2020 por
              el Ing. Ramón Córdova, Ing. Felipe Ávila, Mtro. Ariel Gómez, Ing. Walter Ahrens y M.C. Jonathan Castillo.
              Somos un proyecto que se formó durante la pandemia por COVID-19 con el objetivo de promover la cohetería
              experimental de alta potencia de norte a sur de México
            </p>
            <p className="text-xl text-white text-center">
              Actualmente, ENMICE con el apoyo de sus aliados, colaboradores, patrocinadores y la participación de Rocket
              Scientists, forma parte del esfuerzo nacional para impulsar el crecimiento de la industria espacial en México.

            </p>
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-white text-center"><span style={{ color: '#EE9F24' }}>Comité Ejecutivo</span></h2>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
          >
            {people.map((person) => (
            <li key={person.name}>
              <img className="aspect-[14/13] w-full rounded-1xl object-cover " src={person.imageUrl} alt="" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white" style={{ fontSize: '24px' }}>{person.name}</h3>
              <p className="text-base leading-7 text-gray-300">{person.role}</p>
              <p className="text-sm leading-6 text-gray-500">{person.location}</p>
            </li>
          ))}
          </ul>
        </div>
      </div>
    </div>
    </ScrollSpy>
  )
}