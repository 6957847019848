
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error404 from "./containers/errors/Error404";
import Home from "./containers/pages/Home";
import store from "./store";
import Guias from "containers/pages/Guias";
import Registro from "containers/pages/Registro";
import Colaboradores from "containers/pages/Colaboradores";
import Acerca from "containers/pages/Acerca";
import Eventospasados from "containers/pages/Eventospasados";
import Pregunta from "containers/pages/Pregunta";
import Patrocinadores from "containers/pages/Patrocinadores";
import Patrocinador from "containers/pages/Patrocinador";
import Academia from "containers/pages/Academia";

//import Logo from './src/assets/img';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/*Error Display */}
          <Route path="*" element={<Error404 />} />

          {/*Home Display*/}
          <Route path="/" element={<Home />} />
          <Route path="/Guiash" element={<Guias />} />
          <Route path="/Academia" element={<Academia />} />
          <Route path="/Registro" element={<Registro />} />
          <Route path="/Colaboradores" element={<Colaboradores />} />
          <Route path="/Acerca" element={<Acerca />} />
          <Route path="/Eventospasados" element={<Eventospasados />} />
          <Route path="/Pregunta" element={<Pregunta />} />
          <Route path="/Patrocinadores" element={<Patrocinadores />} />
          <Route path="/Patrocinador" element={<Patrocinador />} />


        </Routes>
      </Router>
    </Provider>

  );
}
export default App;

// Codigo React Login y logotipo.

/*

import React, { Component } from "react";
import logo from './logo.png';
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className="App-title">Blog App</h1>
        </header>

       <p>Estamos mejorando para ti, Convocatoria 2023 Muy Pronto! </p>

        <div className="loginForm">
          <button onClick={() => this.loginForm()}>Login</button>
        </div>
      </div>
    );
  }

  loginForm() {
    console.log("Login rendered");
  }
}

export default App;
*/

