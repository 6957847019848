
import Paulina1 from 'assets/img/Paulina1.jpg'
import Angelica1 from 'assets/img/Angelica1.jpg'
import Paty1 from 'assets/img/Paty1.jpg'
import Fer1 from 'assets/img/Fer1.jpg'
import Angel1 from 'assets/img/Angel1.jpg'
import Emmanuel1 from 'assets/img/Emmanuel1.jpg'
import Jose1 from 'assets/img/Jose1.jpg'

const people = [
  {
    name: <span style={{ color: '#3AAAE1' }}>Paulina Cabrera</span>,
    role: 'Artista digital creativa y diseñadora grafica',
    imageUrl:Paulina1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Angélica Quiñonez</span>,
    role: 'Artista digital creativa y diseñadora grafica',
    imageUrl:Angelica1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Paty Lomelí</span>,
    role: 'Artista digital creativa y diseñadora grafica',
    imageUrl:Paty1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Fernanda Moncada</span>,
    role: 'Analista de marketing',
    imageUrl:Fer1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Angel Vázquez</span>,
    role: 'Desarrollador web',
    imageUrl:Angel1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Emmanuel Ramírez</span>,
    role: 'Desarrollador web',
    imageUrl:Emmanuel1,
    location: '',
  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Emmanuel Bravo</span>,
    role: 'Ejecutivo de alianzas y patrocinios',
    imageUrl:Jose1,
    location: '',
  },
  // More people...
]

export default function Example() {
  return (
    <div className="bg-black py-24 sm:py-5 text-center ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="space-y-12 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-xl font-bold tracking-tight sm:text-4xl text-white text-center"><span style={{ color: '#3AAAE1' }}>EQUIPO 2023</span></h2>
          
          <h2 className="text-xl text-white text-center">
            ENMICE no sería posible <span style={{ fontWeight: '780' }}>sin nuestro gran equipo</span> dividido en áreas de
            comunicaciones, diseño, marketing, alianzas, patrocinios y desarrollo web.
          </h2>
          <h2 className="text-xl text-white text-center">
            Ubicados en diferentes estados del país e incluso fuera de México, en ENMICE creemos firmemente en el
            desarrollo de nuestras actividades de manera remota, <span style={{ fontWeight: '780' }}>¡únete a nosotros y sé parte de la nueva era espacial!</span>
          </h2>
          <h2 className="text-xl text-white text-center">
            Si estás interesado en ser parte de nuestro equipo comunícate con nosotros <span style={{ fontWeight: '780' }}><a href="https://www.ejemplo.com"></a>aqui.</span>
          </h2>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
        >
          {people.map((person) => (
            <li key={person.name}>
              <img className="aspect-[14/13] w-full rounded-1xl object-cover " src={person.imageUrl} alt="" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white" style={{ fontSize: '24px' }}>{person.name}</h3>
              <p className="text-base leading-7 text-gray-300" >{person.role}</p>
              <p className="text-sm leading-6 text-gray-500">{person.location}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}