
import { Typewriter } from 'react-simple-typewriter'
//Fotos de patrocinadores
import UNIV from 'assets/img/universidadautonomachi.jpg'
import VIV from 'assets/img/vivevol.png'
import GOB from 'assets/img/gob.png'
import cuahu from 'assets/img/CdCuauhtémoc.png'
import thrust from 'assets/img/Thrusters.png'
import Buro from 'assets/img/Buró.png'
import Ahchi from 'assets/img/Ah-Chi.png'
import cenaltec from 'assets/img/Cenaltec.png'
import Chihuahuacluster from 'assets/img/Chihuahuacluster.png'
import Index from 'assets/img/Index.png'
import cutonala from 'assets/img/cutonala.png'
import enah from "assets/img/logos_BN/Logo ENAH blanco.png"


import Capital from 'assets/img/ChihuahuCapital.png'
import TLand from 'assets/img/TLand.png'
import Mind from 'assets/img/logos_BN/IndustrialesMindPro.png'
import Explora from 'assets/img/explora.png'
import sie from 'assets/img/sie.png'

import COMAEM from 'assets/img/logos_BN/AEM.png'
import FEMIA from 'assets/img/logos_BN/FEMIA.png'
import AFAC2 from 'assets/img/logos_BN/AFAC.png'


import PRO from 'assets/img/logos_BN/IndustrialesMindPro.png'
import CAJ from 'assets/img/logos_BN/CAJ.png'
import Sayula from 'assets/img/logos_BN/Sayula.png'
import UABC from 'assets/img/logos_BN/UABC.png'
import Marista from 'assets/img/logos_BN/UMG.png'
import Hacia from 'assets/img/logos_BN/Hacia-el-espacio.png'
import BID from 'assets/img/logos_BN/BID360.png'
import Ray from 'assets/img/logos_BN/Raymix.png'
import dereumlab from 'assets/img/logos_BN/Logo-blanco-final-DL.png'
import fairmexico from 'assets/img/logos_BN/logofamexblanco2023.png'
import Spaceg from 'assets/img/logos_BN/SGAC logo blanco.png'
import Hadron from 'assets/img/logos_BN/Hadron logo blanco.png'
import Guerrero1 from 'assets/img/Guerrero.png'
import LaboratorioAEM1 from 'assets/img/LaboratorioAEM.png'
import SANMARCOS from 'assets/img/SNEO.png'
import TL from 'assets/img/TL.png'


//Fotos del carrusel de eventos pasados
//Importamos el carrusel con npm
import Carousel from '@itseasy21/react-elastic-carousel';

export default function Example() {
  //Objeto con las imagenes de los enmice pasados
  //Objeto de las imagenes de los colaboradores
  const colab = [
    {
      id: 0,
      src: UNIV,
      alt: 'Universidad Autonoma de Chihuahua',
      href: 'https://uach.mx/',
      width: 350,
      height: 250,
    },
    {
      id: 1,
      src: COMAEM,
      alt: 'Agencia Espacial Mexicana',
      href: 'https://www.gob.mx/aem',
      width: 350,
      height: 250,
    },
    {
      id: 2,
      src: VIV,
      alt: 'Aca Volamos Alto',
      href: 'https://vivevolando.mx',
      width: 300,
      height: 200,
    },
    {
      id: 3,
      src: GOB,
      alt: 'gobierno municipal chihuahua',
      href: 'https://www.municipiochihuahua.gob.mx',
      width: 180,
      height: 100,
    },
    /*{
      id: 4,
      src: LaboratorioAEM1,
      alt: 'FEMIA',
      href: 'https://haciaelespacio.aem.gob.mx/revistadigital/articul.php?interior=1307',
      width: 350,
      height: 250,
    },*/
    {
      id: 5,
      src: cuahu,
      alt: 'gob municipal cuauhtemoc',
      href: 'http://municipiocuauhtemoc.gob.mx',
      width: 130,
      height: 10,
    },
    {
      id: 6,
      src: FEMIA,
      alt: 'FEMIA',
      href: 'https://femiamx.com/',
      width: 400,
      height: 100,
    },

    {
      id: 7,
      src: AFAC2,
      alt: 'AFAC',
      href: 'https://www.gob.mx/afac',
      width: 150,
      height: 100,
    },
    {
      id: 8,
      src: thrust,
      alt: 'thrust',
      href: 'https://thrustersunlimited.space',
      width: 150,
      height: 100,
    },



    {
      id: 9,
      src: Hadron,
      alt: 'Hadron',
      href: 'https://www.hadron.org.mx',
      width: 220,
      height: 48,
    },
    {
      id: 10,
      src: fairmexico,
      alt: 'fairmexico',
      href: 'https://www.f-airmexico.com.mx',
      width: 110,
      height: 48,
    },
    {
      id: 11,
      src: Hacia,
      alt: 'Hacia el espacio',
      href: 'https://haciaelespacio.aem.gob.mx/revistadigital/',
      width: 300,
      height: 48,
    },
    {
      id: 12,
      src: Buro,
      alt: 'Buro',
      href: 'https://www.visitachihuahua.mx',
      width: 250,
      height: 48,
    },

    {
      id: 13,
      src: Ahchi,
      alt: 'Ah-Chi',
      href: 'https://www.visitachihuahua.mx',
      width: 250,
      height: 48,
    },
    {
      id: 14,
      src: Capital,
      alt: 'Chihuahua capital',
      href: 'https://www.visitachihuahua.mx/',
      width: 250,
      height: 128,
    },

    {
      id: 15,
      src: TLand,
      alt: 'Talent land',
      href: 'https://www.talent-land.mx/',
      width: 150,
      height: 48,
    },
    {
      id: 16,
      src: Mind,
      alt: 'Mind pro',
      href: 'http://www.mindmexico.com',
      width: 270,
      height: 48,
    },
    {
      id: 17,
      src: CAJ,
      alt: 'CAJ ',
      href: 'https://www.facebook.com/CAJaliscoMX/',
      width: 270,
      height: 48,
    },
    {
      id: 19,
      src: Explora,
      alt: 'Explora ',
      href: 'https://sites.google.com/view/serviciosexploracionespacial/servicios',
      width: 270,
      height: 48,
    },
    {
      id: 20,
      src: Spaceg,
      alt: 'Spaceg ',
      href: 'https://spacegeneration.org',
      width: 270,
      height: 48,
    },
    {
      id: 21,
      src: BID,
      alt: 'BID ',
      href: 'https://bid360.mx/',
      width: 270,
      height: 48,
    },
    {
      id: 22,
      src: cenaltec,
      alt: 'cenaltec ',
      href: 'https://www.inadet.com.mx',
      width: 270,
      height: 48,
    },
    {
      id: 23,
      src: Chihuahuacluster,
      alt: 'chihuahua aeroespacial cluster',
      href: 'https://aeroclusterchihuahua.com/en/home-2/',
      width: 270,
      height: 48,
    },
    {
      id: 24,
      src: Index,
      alt: 'index',
      href: 'https://indexchihuahua.org.mx/',
      width: 270,
      height: 48,
    },

    {
      id: 25,
      src: dereumlab,
      alt: 'Dereum ',
      href: 'https://dereumlabs.com',
      width: 270,
      height: 48,
    },
    {
      id: 26,
      src: sie,
      alt: 'siemens ',
      href: 'https://www.siemens.com/mx/es.html',
      width: 270,
      height: 48,
    },
    {
      id: 27,
      src: Sayula,
      alt: 'sayula ',
      href: 'https://www.sayula.gob.mx',
      width: 100,
      height: 48,
    },
    {
      id: 28,
      src: cutonala,
      alt: 'cut ',
      href: 'https://www.cutonala.udg.mx/',
      width: 270,
      height: 48,
    },
    {
      id: 29,
      src: Marista,
      alt: 'umg',
      href: 'https://umg.edu.mx/portal/',
      width: 130,
      height: 48,
    },
    {
      id: 30,
      src: enah,
      alt: 'enah',
      href: 'https://www.enah.edu.mx',
      width: 270,
      height: 48,
    },
    {
      id: 31,
      src: Ray,
      alt: 'raymix',
      href: 'https://www.instagram.com/raymixmusic',
      width: 270,
      height: 48,
    },
    {
      id: 32,
      src: UABC,
      alt: 'UABC',
      href: 'https://citecuvp.tij.uabc.mx',
      width: 270,
      height: 48,
    },


  ]
  //Información para que el carrusel sea responsive
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 480, itemsToShow: 2, itemsToScroll: 2 },
    { width: 750, itemsToShow: 3, itemsToScroll: 3 },
  ]
  return (

    <div className="bg-black py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-10">
        <h1 className="text-3xl font-bold tracking-tight sm:text-4xl text-white text-center">
          <span style={{ color: '#3AAAE1' }}>Nuestros aliados y colaboradores</span>
        </h1>
        <h2 className="text-xl text-white text-center mt-8">

          ENMICE no sería posible sin el apoyo, respaldo y reconocimiento de nuestros aliados y colaboradores como la
          Agencia Espacial Mexicana (AEM), Federación Mexicana de la Industria Aeroespacial (FEMIA) y la Agencia Federal de
          Aviación Civil (AFAC) que confiaron en el proyecto desde el inicio y todos quienes han sido pilares para el desarrollo del evento
        </h2>
      </div>
      <div>
        <div className=' w-full grid grid-cols-3 grid-flow-row gap-1 pt-4 max-lg:grid-cols-2 max-md:grid-cols-1 bg-black'>
          {colab.map((index) => (
            <a href={index.href} target="_blank" className='flex m-auto h-40 w-[450px]  align-middle max-xl:w-[370px]'>
              <img
                className='hover:scale-125 ease-in duration-300  m-auto'
                src={index.src}
                alt={index.alt}
                width={index.width}
                height={index.height}
              />
            </a>
          ))}
        </div>

      </div>
      {/* 
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-left gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <a href="https://www.gob.mx/aem">
            <img
              className="col-span-50 max-h-150 w-full object-contain lg:col-span-1"
              src={COMAEM}
              alt="Agencia Espacial Mexicana"
              href="https://www.gob.mx/aem"
              width={740}
              height={300}
            />
          </a>
          <a href="https://femiamx.com">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-2"
              src={FEMIA}
              alt="FEMIA"
              href="https://femiamx.com"
              width={200}
              height={100}
            />
          </a>
          <a href="https://www.gob.mx/afac">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={AFAC2}
              alt="AFAC"
              href="https://www.gob.mx/afac"
              width={80}
              height={10}
            />
          </a>
        </div>

        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-2">

          <a href="http://www.mindmexico.com/">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={MiNDPRO}
              alt="Equipos Pro"
              href="http://www.mindmexico.com/"
              width={200}
              height={100}
            />
          </a>

        </div>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <a href="https://umg.edu.mx/portal/">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Marista}
              alt="Marista"
              href="https://umg.edu.mx/portal/"
              width={158}
              height={48}
            />

          </a>
          <a href="https://www.uabc.mx">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={UABC}
              alt="Universidad Autonoma de Baja California"
              href="https://www.uabc.mx"
              width={158}
              height={48}
            />
          </a>
          <a href="https://haciaelespacio.aem.gob.mx/revistadigital/">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Hacia}
              alt="Hacia el espacio"
              href="https://haciaelespacio.aem.gob.mx/revistadigital/"
              width={158}
              height={48}
            />
          </a>
        </div>

        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <a href="https://bid360.mx">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={BID}
              alt="Baja Aeroespace"
              href="https://bid360.mx"
              width={158}
              height={48}
            />
          </a>
          <a href="https://www.sayula.gob.mx">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Sayula}
              alt="Ayuntamiento Sayula"
              href="https://www.sayula.gob.mx"
              width={158}
              height={48}
            />
          </a>
          <a href="http://bajaaerospace.org">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Baja}
              alt="Baja Aerospace"
              href="https://haciaelespacio.aem.gob.mx/revistadigital/"
              width={158}
              height={48}
            />
          </a>
        </div>

        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
            src={Ray}
            alt="Baja Aeroespace"
            width={158}
            height={48}
          />
          <a href="http://www.unisec-global.org">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Unisec}
              alt="Ayuntamiento Sayula"
              href="https://www.sayula.gob.mx"
              width={158}
              height={48}
            />

          </a>
          <a href="https://www.tripolimexico.org">
            <img
              className="col-span-8 max-h-48 w-full object-contain lg:col-span-1"
              src={Mexico}
              alt="Baja Aerospace"
              href="https://haciaelespacio.aem.gob.mx/revistadigital/"
              width={158}
              height={48}
            />
          </a>
        </div>
        */}
    </div>
  )
}