import { Typewriter } from 'react-simple-typewriter'
// 3:35:34


function Header() {

  const downloadUrl = 'https://drive.google.com/file/d/1iBtHnplPfAuQta3NORge_lrvTftvdDbo/view?usp=sharing';
  const downloadUrl2 = 'https://drive.google.com/file/d/1J2Niumn4YoNMG36nqr2W7uWJNapxbpiZ/view?usp=sharing';
  const downloadUrl3 = 'https://drive.google.com/file/d/1tNG7sttCA2YUNUS59ktdYKUHbU5Rse3E/view?usp=sharing';
  const downloadUrl4 = 'https://drive.google.com/file/d/1_EUmiyXjGJYwBaUdJyEMjWQnONuqELl9/view?usp=drive_link';
  const downloadUrl5 = 'https://drive.google.com/file/d/17fBFjixVcCt_gjdl2q0Wc1XGvHc5jV3u/view?usp=drive_link';
  const downloadUrl6 = 'https://drive.google.com/file/d/1JqHsgqgoLVDCcj0PmxJdsqROMwLXxgjV/view?usp=drive_link';
  const downloadUrl7 = 'https://drive.google.com/file/d/1SCl8Ie2q5Lni8aQWGAEo1_MHt5TM0-fX/view?usp=drive_link';
  const downloadUrl8 = 'https://drive.google.com/file/d/1tLt-PQfMEudCX5bZNd9smWEAtTO7hbG8/view?usp=drive_link';
  const downloadUrl9 = 'https://drive.google.com/file/d/19A6YkkwOT72O1Fkz1eiS5fMmd0sTJ34U/view?usp=drive_link';
  const downloadUrl10 = 'https://drive.google.com/file/d/1s99Ipag2g9t1HjZfxDHMG2JL0UpEHV6h/view?usp=drive_link';
  const downloadUrl11 = 'https://drive.google.com/file/d/1Yo1Jdlon_T9xbndI3JzedHsUg1bEFELk/view?usp=drive_link';



  return (
    <main>
      <div className="bg-black py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-10">
          <div>
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            </div>
            <div>
              <h1 className="text-3xl font-bold tracking-tight sm:text-4xl text-white text-center">
                <span style={{ color: '#3AAAE1' }}>Guías y documentos de apoyo para equipos competidores ENMICE 2023</span>
              </h1>
              <h1 className="text-white text-4xl font-bold tracking-tight sm:text-center sm:text-4xl">

              </h1>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl} target="_blank" rel="noopener noreferrer"
                >
                  GUÍA DE DISEÑO, PRUEBA Y EVALUACIÓN (GDPE) ENMICE 2023
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl2} target="_blank" rel="noopener noreferrer"
                >
                  GUÍA DE PROCEDIMIENTOS Y OPERACIONES ESTANDARIZADAS (GPOE) ENMICE 2023
                </a>
                <div className="text-xl text-white text-center mt-8">
                  <a
                    href={downloadUrl3} target="_blank" rel="noopener noreferrer"
                  >
                    GUÍA DE REGLAS Y REQUISITOS (GRR) ENMICE 2023
                  </a>
                </div>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl4} target="_blank" rel="noopener noreferrer"
                >
                  LEY FEDERAL DE ARMAS DE FUEGO Y EXPLOSIVOS (DOF 06-12-2022)
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl9} target="_blank" rel="noopener noreferrer"
                >
                  REGLAMENTO DE LA LEY FEDERAL DE ARMAS DE FUEGO Y EXPLOSIVOS (DOF 06-04-1972)
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl5} target="_blank" rel="noopener noreferrer"
                >
                  NMX-AE-001-SCFI-2018
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl6} target="_blank" rel="noopener noreferrer"
                >
                  NMX-AE-002-SCFI-2019
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl7} target="_blank" rel="noopener noreferrer"
                >
                  NOM-009-SCT2_2009
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl8} target="_blank" rel="noopener noreferrer"
                >
                  NOM-107-SCT3-2019-201119
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl10} target="_blank" rel="noopener noreferrer"
                >
                  PREPARATION OF PAPERS FOR AIAA TECHNICAL CONFERENCES
                </a>
              </div>
              <div className="text-xl text-white text-center mt-8">
                <a
                  href={downloadUrl11} target="_blank" rel="noopener noreferrer"
                >
                  GUÍA DE LA MISIÓN ESPACIAL - CONCURSO IBEROAMERICANO SATÉLITES ENLATADOS 2023
                </a>
              </div>
            </div>
            <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
              <svg
                className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                  fillOpacity=".3"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset={1} stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </main>
  )



}

export default Header