
import {connect} from "react-redux";

function Layout({children}){
    return(
        <div>
            {children}
        </div>
    )
}

const mapStateToProp = state =>({

})

export default connect(mapStateToProp, {

}) (Layout)

