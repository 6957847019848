import { Typewriter } from 'react-simple-typewriter'
import { React, useState, useEffect, useRef } from 'react';
import Carousel from '@itseasy21/react-elastic-carousel';
import Real from 'assets/img/Real.png'
import Equipo3 from 'assets/img/Equipo3.jpg'
import Fondo1 from 'assets/img/Fondo-01.jpg'
import Fondo2 from 'assets/img/Fondo-02.jpg'
import Fondo3 from 'assets/img/Fondo-03.jpg'
import Fondo4 from 'assets/img/Fondo-04.jpg'
import Fondo5 from 'assets/img/Fondo-05.jpg'
import Fondo6 from 'assets/img/Fondo-06.jpg'
import Fondo7 from 'assets/img/Fondo-07.jpg'
import Fondo8 from 'assets/img/Fondo-08.jpg'



const breakPoints1 = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 480, itemsToShow: 1, itemsToScroll: 1 },
  { width: 750, itemsToShow: 1, itemsToScroll: 1 },
];

function Header() {
  const downloadUrl = 'https://forms.gle/v1vvCJjmgn8UNULN6';
  const downloadUrl2 = 'https://drive.google.com/file/d/1V93qAzHGXT3sq2lgybS9Z4gH46vNHt7O/view?usp=sharing';
  const carruselImgs = [
    {
      id: 0,
      src: Fondo1,
      alt: 'Foto de equipos'
    },
    {
      id: 1,
      src: Fondo2,
      alt: 'Foto de equipos'
    },
    {
      id: 2,
      src: Fondo3,
      alt: 'Foto de equipos'
    },
    {
      id: 3,
      src: Fondo4,
      alt: 'Foto de equipos'
    },
    {
      id: 4,
      src: Fondo5,
      alt: 'Foto de equipos'
    },
    {
      id: 5,
      src: Fondo6,
      alt: 'Foto de equipos'
    },
    {
      id: 6,
      src: Fondo7,
      alt: 'Foto de equipos'
    },
    {
      id: 7,
      src: Fondo8,
      alt: 'Foto de equipos'
    }
  ];

  const carouselRef = useRef(null);
  const itemsPerPage = 1;
  const totalPages = Math.ceil(carruselImgs.length / itemsPerPage);
  let resetTimeout;

  return (
    <main>
      <div className=" relative px-8 lg:px-5 h-screen ">
        {/* Carrusel Automatico*/}
        <div className="absolute  inset-0 -z-10 h-full w-full bg-black">
          <Carousel
            itemsToScroll={2}
            pagination={false}
            itemPadding={[0, 0]}
            breakPoints={breakPoints1}
            enableAutoPlay
            autoPlaySpeed={4000}
            ref={carouselRef}
            showArrows={false}
            onNextEnd={({ index }) => {
              clearTimeout(resetTimeout);
              if (index + 1 === totalPages) {
                resetTimeout = setTimeout(() => {
                  carouselRef.current.goTo(0);
                }, 4000);
              }
            }}
            itemsToShow={itemsPerPage}
          >
            {carruselImgs.map((index) => (
              <div className="bg-black w-full flex justify-center max-sm:pb-[300px] m-0" key={index.id}>
                <div className="w-full h-[800px] max-lg:h-[500px] max-sm:h-[300px] m-0">
                  <img
                    className="w-full h-full m-0"
                    src={index.src}
                    alt={index.alt}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="  px-10 py-19 sm:py-40 lg:px-8 absolute top-0">
          <div className="flex max-sm:flex-col">
            <div style={{ flex: 1, backgroundColor: '', marginTop: "270px" }}>
              {/* Lado derecho con texto */}
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-5xl">EL EVENTO Y COMPETENCIA
                LÍDER DE COHETERÍA</h1>

              <h2 className="text-white max-sm:text-white font-bold text-3xl">EXPERIMENTAL EN MÉXICO</h2>
              <div className="mt-10 flex items-center justify-left gap-6 max-sm:flex-col">
                <a
                  href={downloadUrl2} target="_blank" rel="noopener noreferrer"
                  className="rounded-md bg-custom-color4 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm border-2 border-white hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  CONVOCATORIA 2023
                </a>
                {/*<a
                  href={downloadUrl} target="_blank" rel="noopener noreferrer"
                  className="rounded-md bg-custom-color5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  border-2 border-white hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  REGISTRO
            </a> */}
                <a
                  href="https://bit.ly/ticketsenmice20232024"
                  className="rounded-md bg-custom-color6 px-3.5 py-2.5 text-sm font-semibold text-white text-center border-2 border-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  CONSIGUE TU ENTRADA AQUÍ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Header;


/*
<div className="mt-8 flex gap-x-4 sm:justify-center">
                          <a
                            href="../"
                            className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                          >
                            Iniciar
                            <span className="text-indigo-200" aria-hidden="true">
                              &rarr;
                            </span>
                          </a>
                          <a
                            href="../Quienes"
                            className="inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                          >
                            Mas informacion
                            <span className="text-gray-400" aria-hidden="true">
                              &rarr;
                            </span>
                          </a>
                    </div>
*/

// Linea de codigo 76

/*
<svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                  <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#9089FC" />
                      <stop offset={1} stopColor="#FF80B5" />
                    </linearGradient>
                  </defs>
                </svg>
*/

/*
<span className="text-white">
                      "Bienvenidos al mundo de los sueños"...{' '}
                      <a href="https://www.nasa.gov/" className="font-semibold text-indigo-600">
                        <span className="absolute inset-0" aria-hidden="true" />
                        Read more <span aria-hidden="true">&rarr;</span>
                      </a>
                    </span>
*/

/*
<div className="hidden sm:mb-8 sm:flex sm:justify-center">
                    
                        <img className="centro"
                        src={Real}
                        width={300}
                        height={300}
                      />
                    
                </div>
*/