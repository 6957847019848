import Calendario1 from 'assets/img/Calendario1.jpg'
import Aceptados1 from 'assets/img/Aceptados1.jpg'
import Aceptados2 from 'assets/img/Aceptados2.jpg'
import ListaFinal from 'assets/img/ListaFinal.jpg'


export default function Example() {
  return (
    <div className="bg-black">
      <div className="px-6 py-10 sm:px-6 sm:py-10 lg:px-8">
        <div className="text-3xl  tracking-tight sm:text-4xl text-white text-center">
          <h2 className=" text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <span style={{ color: '#3AAAE1' }}>CONVOCATORIA</span><span style={{ color: '#EE9F24' }}> 2024</span>
            <br />
          </h2>
          <p className="text-xl text-white text-center mt-8">
            Este 2024 por primera vez, ENMICE reunirá equipos provenientes de todo el país en 3 categorías diferentes: presentación de proyectos, Lanzamiento con Carga Útil y Carga Útil, formados por estudiantes, investigadores, profesionales y entusiastas de la cohetería experimental, rovers y satélites pequeños en la  competencia de ingeniería y lanzamiento de vehículos lanzadores líder en México, culminando con la premiación y reconocimiento de los equipos ganadores.
          </p>
          <br />
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white"><span style={{ color: '#EE9F24' }}>¡FELICIDADES A TODOS LOS EQUIPOS ACEPTADOS</span></h2>
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white"><span style={{ color: '#3AAAE1' }}></span></h2>
          <div className="mt-10 flex items-center justify-center gap-6 max-sm:flex-col">
            <a
              href="https://drive.google.com/file/d/1Kb1SoW7sLeF4sxNAgUrWpQeNp20iW2MJ/view?usp=sharing" target="_blank" rel="noopener noreferrer"
              className=" border-2 border-white rounded-md bg-custom-color px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
            >
              CONVOCATORIA 2023 - 2024
            </a>

            <a
              href="https://drive.google.com/drive/folders/1be0LoOcsy0qoUpX2yYJ2Y9iHLbrzqTdB?usp=sharing" target="_blank" rel="noopener noreferrer"
              className=" border-2 border-white rounded-md bg-custom-color3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
            >
              GUÍAS PARA EQUIPOS
            </a>
            <a
              href="#"
              className=" border-2 border-white rounded-md bg-custom-color5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
            >
              PREGUNTAS FRECUENTES
            </a>
          </div>
        </div>
        <br></br><br></br>
        {/*<img className='rounded-2xl' src={Aceptados1} alt="Logo" style={{ width: '100%', height: 'auto', maxWidth: '500px', display: 'block', margin: '0 auto' }} />
        <br></br><br></br>
  <img className='rounded-2xl' src={Aceptados2} alt="Logo" style={{ width: '100%', height: 'auto', maxWidth: '500px', display: 'block', margin: '0 auto' }} />*/}
        <br></br><br></br>
      </div>
    </div>
  )
}