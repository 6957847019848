
const ContactoComponente = () => {
    const estiloDeTexto = {
        backgroundImage: 'linear-gradient(to right, #2b678c, #138e96)',
        color: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        fontSize: '2.5rem',
    };

    const estiloNegro = {
        color: 'black',
        fontWeight: 'normal',
        fontSize: '1.5rem',
    };

    const estiloNegro2 = {
        color: 'black',
        fontWeight: 'normal',
        fontSize: '2rem',  // Esto asegura que el texto no esté en negrita
    };

    return (
        <div className="text-center py-16">
            <p className="text-xl mb-4" style={estiloNegro2}>
                ¿Eres una institución, centro de investigación, grupo o empresa?
            </p>
            <p className="text-lg mb-4" style={estiloNegro2}>
                Contáctate con nosotros y personalizamos el curso o capacitación de acuerdo a tus objetivos.
            </p>
            <p className="font-bold" style={estiloDeTexto}>
                academia@enmice.mx{' '}
                <span style={estiloNegro}>o al 33 4403 2510</span>
            </p>
        </div>
    );
};

export default ContactoComponente;