const estiloDeTexto = {
    backgroundImage: 'linear-gradient(to right, #2b678c, #138e96)',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
};

const estiloNegro = {
    color: 'black',
    fontWeight: 'normal', // Esto asegura que el texto no esté en negrita
};

export const AcademiaInicio = () => {
    return (
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
                <div className="flex items-center justify-center lg:w-1/2">
                    <div className="w-3/5 ml-4 lg:ml-0">
                        <img className="object-cover" src="https://i.ibb.co/D49Bxfq/Academia-ENMICE.png" alt="" />
                    </div>
                </div>
                <div className="mb-0 lg:max-w-lg lg:pl-5">
                    <div className="max-w-xl mb-6">
                        <h2 className="font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none max-w-lg mb-6">
                            <p className="mb-2" style={{ ...estiloDeTexto, fontSize: '3rem' }}>Impulsa tu conocimiento en el</p>
                            <p className="mb-2" style={estiloNegro}>Sector Aeroespacial</p>
                            <p className="mb-2" style={{ ...estiloDeTexto, fontSize: '3rem' }}>Con nosotros</p>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcademiaInicio;
