import React from 'react';
import Logo_negro from 'assets/img/Logo_negro.png'
import Real from 'assets/img/Real.png'
import Poster2023 from 'assets/img/POSTERS-2023-2024.png'

const downloadUr = "https://bit.ly/callfortalks-enmice2023"

const MyComponent = () => {
  return (
    <div className="bg-black px-6 py-30 sm:py-20 lg:px-8">
      <div className='flex max-sm:flex-col'>
        <div style={{ flex: 0.7, backgroundColor: 'black', marginTop: "10px" }}>
          {/* Lado izquierdo con logotipo */}
          <img className='rounded-3xl' src={Poster2023} alt="Logo" style={{ width: '90%', height: 'auto', margin: '0 auto' }} />
        </div>
        <div className="lg:px-10" style={{ flex: 1, backgroundColor: 'black' }}>
          {/* Lado derecho con texto */}
          <h1 className="mt-20 text-3xl font-bold tracking-tight text-white sm:text-4xl"><span style={{ color: '#3AAAE1' }}>¿QUÉ ES</span><span style={{ color: '#EE9F24' }}> ENMICE?</span></h1>
          <br></br>
          <h2 className="text-white">El Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE), es el evento y competencia líder de Cohetería Experimental en México, que, con el apoyo de sus aliados, colaboradores y patrocinadores, forma parte del esfuerzo nacional para impulsar la participación y crecimiento de la industria espacial mexicana, reuniendo, fomentando y reconociendo el talento nacional en materia de ciencia y tecnología espacial.
          </h2>
          <br></br>
          <p className="text-white">
          </p>

          <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"><span style={{ color: '#3AAAE1' }}>EDICIÓN </span> <span style={{ color: '#EE9F24' }}>2023-2024</span></h1>

          <br></br>
          <p className="text-white">
            <span style={{ color: 'white' }}>Este año el objetivo de ENMICE es llevar la cohetería experimental a nuevas regiones de México con el apoyo de sus aliados, colaboradores y patrocinadores. Ya estuvimos presentes en el norte, en el centro del país y este 2024 es momento de encontrarnos en el Norte de México. En su tercera edición, durante 3 días, tendrás acceso a todas las actividades que tenemos para ti en materia espacial: conferencias, talleres, cursos, mesas redondas, networking con nuestros invitados nacionales e internacionales, así como presenciar los lanzamientos de cohetes experimentales creados por los equipos que participan en la competencia de ENMICE y que construyen actualmente el sector espacial en México.</span>

          </p>
          <div className="mt-10 flex items-center justify-left gap-6 max-sm:flex-col">
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;