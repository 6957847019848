import React from 'react';
import imagen from '../../assets/img/COMUNICADO1.jpg';

const MyComponent = () => {
  const downloadUrl = 'https://ibb.co/gZ18KR8'; // URL de la imagen (ajustada al enlace correcto)

  return (
    <div className="bg-black px-6 py-20 sm:py-10 lg:px-8">
      <div className="w-full max-w-screen-xl h-auto rounded-md overflow-hidden mx-auto">
        <img
          src={imagen}
          alt="Imagen"
          width="1150"
          className="w-full h-auto object-cover rounded-md mx-auto"
        />
      </div>
    </div>
  );
};

export default MyComponent;
