

import Evento2021 from 'assets/img/Evento2021.jpg'
import Cartel1 from 'assets/img/Cartel1.jpg'

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'



export default function Example() {
  return (
    <div className="overflow-hidden bg-black py-20 sm:py-30">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="">
            <div className="lg:max-w-lg">
              <p className="text-3xl font-bold tracking-tight text-white sm:text-4xl"><span style={{ color: '#3AAAE1' }}>Encuentro Mexicano de Ingenieria en Coheteria Experimental ENMICE - 2021</span></p>
              <br></br>
              <h2 className="text-base leading-7 text-white"><span style={{ color: '#EE9F24' }}>Universidad Autonoma de Baja California, Laguna Salada, Tijuana Baja California./ 28 al 30 de mayo 2021</span></h2>
              <p className="mt-6 text-lg leading-6 text-white" style={{ textAlign: 'justify' }}>
              Motivados por el desarrollo del capital humano, tecnologia y ciencia espacial en Mexico, se lleva a cabo por primera vez con el respaldo de la Agencia Espacial Mexicana la edicion 2021 del Encuentro Mexicano de Ingenieria y Coheteria Experimental (ENMICE).
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              </dl>
            </div>
          </div>
          <div className=" items-start ">
            <img
              src={Cartel1}
              alt="ENMICE2023"
              className="w-full sm:w-[25rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  )
}