
const AcademiaCalen = () => {
    return (
        <div className="text-center">
            <img
                src="https://i.ibb.co/YkrjnqM/Calendario.jpg"
                alt="Postales-promocion-Academia-ENMICE-Q1-bordes-circulares"
                className="mx-auto mb-8 w-full h-screen object-contain"
            />
        </div>
    );
};

export default AcademiaCalen;