import React from 'react';
import Real from 'assets/img/Real.png';
import Steren from 'assets/img/Steren.png';
import Hero from 'assets/img/Hero.png';
import { Typewriter } from 'react-simple-typewriter'
//Fotos de patrocinadores
import COMAEM from 'assets/img/COMAEM.png'
import FEMIA from 'assets/img/FEMIA.png'
import AFAC2 from 'assets/img/AFAC2.jpg'
import Marista from 'assets/img/Marista.png'
import UABC from 'assets/img/UABC.png'
import Hacia from 'assets/img/Hacia.png'
import Baja from 'assets/img/Baja.png'
import BID from 'assets/img/BID.png'
import Sayula from 'assets/img/Sayula.png'
import Ray from 'assets/img/Ray.png'
import Unisec from 'assets/img/Unisec.jpg'
import Mexico from 'assets/img/Mexico.png'
import MiNDPRO from 'assets/img/MiNDPRO.png'
//Fotos del carrusel de eventos pasados
import pasado1 from 'assets/img/ENMICE_PASADO/pasado1.jpg'
import pasado2 from 'assets/img/ENMICE_PASADO/pasado2.JPG'
import pasado3 from 'assets/img/ENMICE_PASADO/pasado3.JPG'
import pasado4 from 'assets/img/ENMICE_PASADO/pasado4.jpg'
import pasado5 from 'assets/img/ENMICE_PASADO/pasado5.JPG'
import pasado6 from 'assets/img/ENMICE_PASADO/pasado6.JPG'
import pasado7 from 'assets/img/ENMICE_PASADO/pasado7.JPG'
import pasado8 from 'assets/img/ENMICE_PASADO/pasado8.JPG'
import pasado9 from 'assets/img/ENMICE_PASADO/pasado9.JPG'
import pasado10 from 'assets/img/ENMICE_PASADO/pasado10.JPG'
//Importamos el carrusel con npm
import Carousel from '@itseasy21/react-elastic-carousel';

const colaboradores = [
  {
    src: pasado1,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado2,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado3,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado4,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado5,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado6,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado7,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado8,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado9,
    alt: "Foto de eventos pasados",
  },
  {
    src: pasado10,
    alt: "Foto de eventos pasados",
  },
]
//Información para que el carrusel sea responsive
const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 480, itemsToShow: 2, itemsToScroll: 2 },
  { width: 750, itemsToShow: 3, itemsToScroll: 3 },
]


const YouTube = () => {

  return (
    <div className='bg-black px-6 py-24 sm:py-10 lg:px-8 flex justify-center'>
      <div className=' flex max-lg:flex-col'>
        <div style={{ flex: 0.5, margin: '10px auto' }}>
          {/* Lado izquierdo con el video de YouTube */}
          <div className="video-container flex justify-center">
            {/* Agrega aquí tu reproductor de YouTube */}
            {/* Video PC */}
            <iframe
              className='max-md:hidden'
              width="800"
              height="500"
              src="https://www.youtube.com/embed/P7oHPDOM55Q"
              title="Agencia Espacial Mexicana y Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE) 2022"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in -picture; web-share"
              allowfullscreen>
            </iframe>
            {/* Video mobile */}
            <iframe
              className='md:hidden'
              width="360"
              height="180"
              src="https://www.youtube.com/embed/P7oHPDOM55Q"
              title="Agencia Espacial Mexicana y Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE) 2022"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
          <div >
            <div >
              <h1 className="text-3xl pt-24 font-bold tracking-tight text-white sm:text-4xl text-center "><span style={{ color: '#3AAAE1' }}>Revive el ENMICE 2022</span></h1>
            </div>
            <div >
              <h2 className="text-xl text-white text-center mt-6">SEPTIEMBRE 22 – 25</h2>
              <h2 className="text-xl text-white text-center mt-6">UNIVERSIDAD MARISTA DE GUADALAJARA / LAGUNA DE SAYULA</h2>
            </div>
            <div>
              <p className="text-white text-center">
                El ENMICE en colaboración con la Agencia Espacial Mexicana (AEM), redobla esfuerzos en sus dos ediciones, Baja California 2021 y Jalisco 2022, para posicionarse como referente en el sector espacial, específicamente en el impulso del diseño y desarrollo de vehículos lanzadores experimentales en México
              </p>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default YouTube;

