export default function AcademiaOfertas() {
    const callouts = [
        {
            name: '',
            description: '',
            imageSrc: 'https://i.ibb.co/HGjBgzd/Hidrocohetes-Q1-Academia-ENMICE-bordes-circulares.jpg',
            imageAlt: 'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
            href: '#',
        },
        {
            name: '',
            description: '',
            imageSrc: 'https://i.ibb.co/HBv2J6W/Temario-Introducci-n-a-cohetes-bordes-circulares.jpg',
            imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
            href: '#',
        },
        {
            name: '',
            description: '',
            imageSrc: 'https://i.ibb.co/1JGxJ4M/Capacitacion-seguridad-bordes-circulares.jpg',
            imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
            href: '#',
        },
    ];

    return (
        <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <div className="text-center">
                        <img src="https://i.ibb.co/jHbHT48/imagen-2024-01-04-141106484.png" alt="Imagen de ejemplo" className="mb-4 mx-auto" />
                        <h2 className="text-2xl font-bold text-gray-900">
                            Consulta las ofertas de cursos, capacitaciones y talleres
                        </h2>
                    </div>

                    <div className="mt-0 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
                        {callouts.map((callout) => (
                            <div key={callout.name} className="group relative">
                                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                                    <img
                                        src={callout.imageSrc}
                                        alt={callout.imageAlt}
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <h3 className="mt-6 text-sm text-gray-500">
                                    <a href={callout.href}>
                                        <span className="absolute inset-0" />
                                        {callout.name}
                                    </a>
                                </h3>
                                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-center">
                        <a
                            href="https://bit.ly/AcademiaENMICEQ1-24"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-700 hover:to-blue-900 text-white font-bold py-8 px-16 rounded-full transition-transform transform hover:scale-108 text-lg"
                            style={{
                                backgroundImage: 'linear-gradient(to right, #2b678c, #138e96)',
                            }}
                        >
                            REGÍSTRATE AQUÍ
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}