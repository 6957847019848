
import Footer from "componets/navigation/Footer"
import Navbar from "componets/navigation/Navbar"
import Layout from "hocs/layouts/Layout"
import AcademiaInicio from "componets/Academia/AcademiaInicio"
import AcademiaOfertas from "componets/Academia/AcademiaOfertas"
import AcademiaCalen from "componets/Academia/AcademiaCalen"
import AcademiaContacto from "componets/Academia/AcademiaContacto"

function Academia() {
    return (
        <Layout>
            <Navbar />
            <div className="pt-16">
                <AcademiaInicio />
                <AcademiaOfertas />
                <AcademiaCalen />
                <AcademiaContacto />
            </div>
            <Footer />
        </Layout>
    )

}
export default Academia

