

import { Typewriter } from 'react-simple-typewriter'

//patrocinadores a color
/*
import Steren from  'assets/img/Steren.png'
import UDEG from 'assets/img/UDEG.png'
import SSC from 'assets/img/SSC.png'
import Ans from 'assets/img/Ans.png'
import RedCola3 from 'assets/img/RedCola3.png'
import BID from 'assets/img/BID.png'
import Best2 from 'assets/img/Best2.jpg'
import Sidral from 'assets/img/Sidral.png'
import Agua from 'assets/img/Agua.png'
import Craft from 'assets/img/Craft.png'
import Hero from 'assets/img/Hero.png'
import OLEAN from 'assets/img/OLEAN.png'
import Era from 'assets/img/Era.png'
import Muebles from 'assets/img/Muebles.png'
import Hadron from 'assets/img/Hadron.png'
*/
//patrocinadores en blanco y negro
import UDEG from 'assets/img/logos_BN/UDG.png'
import Steren from 'assets/img/logos_BN/Steren.png'
import SSC from 'assets/img/logos_BN/Grupo-SSC-Ansys.png'
import INBEST from 'assets/img/logos_BN/iNBest.png'
import RedCola from 'assets/img/logos_BN/Red-Cola.png'
import BID from 'assets/img/logos_BN/BID360.png'
import Skarch from 'assets/img/logos_BN/Skarch.png'
import Craft from 'assets/img/logos_BN/Craft-Avia.png'
import Sidral from 'assets/img/logos_BN/Sidral-Aga.png'
import Murph from 'assets/img/logos_BN/Morph-Industries.png'
import Naranjo from 'assets/img/logos_BN/Naranjo.png'
import Rocketeria from 'assets/img/logos_BN/Rocketeria.png'
import Muebles from 'assets/img/logos_BN/SM-muebles-y-decoracion.png'
import Share from 'assets/img/share.png'
import Aquo from 'assets/img/aquo.png'
import LogoNoMADA from 'assets/img/LogoNoMADA.png'
import Marsal from 'assets/img/Marsal.png'
import AXON from 'assets/img/AXON.png'
import explora from 'assets/img/explora.png'
import Dew from 'assets/img/Dew.png'
import MaG from 'assets/img/logos_BN/MaG.png'



export default function Patron1() {
  const patron = [
    {
      id: 0,
      src: MaG,
      alt: 'MaG',
      href: 'https://umg.edu.mx/portal/',
      width: 230,
      height: 100,
    },
    {
      id: 1,
      src: Dew,
      alt: 'Dew',
      href: 'https://dewesoft.com/es',
      width: 200,
      height: 300,
    },
    {
      id: 2,
      src: SSC,
      alt: 'Grupo SSC',
      href: 'https://www.grupossc.com',
      width: 330,
      height: 10,
    },
    {
      id: 3,
      src: Share,
      alt: 'Share',
      href: 'https://sharevolts.mx/',
      width: 280,
      height: 100,
    },
    {
      id: 4,
      src: LogoNoMADA,
      alt: 'LogoNoMADA',
      href: 'https://nomada-e.com/',
      width: 200,
      height: 100,
    },
    {
      id: 5,
      src: Marsal,
      alt: 'Marsal',
      href: 'http://www.aquosmic.com/',
      width: 150,
      height: 100,
    },
    {
      id: 6,
      src: BID,
      alt: 'Bid360',
      href: 'https://bid360.mx',
      width: 200,
      height: 100,
    },
    {
      id: 7,
      src: AXON,
      alt: 'AXON',
      href: 'https://www.axon-cable.com/en/00_home/00_start/00/index.aspx',
      width: 120,
      height: 100,
    },
    {
      id: 8,
      src: explora,
      alt: 'explora',
      href: 'https://sites.google.com/view/serviciosexploracionespacial/servicios',
      width: 280,
      height: 100,
    },
    {
      id: 9,
      src: RedCola,
      alt: 'RedCola',
      href: 'https://redcola.mx/',
      width: 160,
      height: 48,
    },
    {
      id: 10,
      src: Steren,
      alt: 'steren',
      href: 'https://www.steren.com.mx/',
      width: 100,
      height: 48,
    },
    {
      id: 11,
      src: Sidral,
      alt: 'Sidral',
      href: 'https://www.sidralaga.com/',
      width: 180,
      height: 48,
    },
    {
      id: 12,
      src: Murph,
      alt: 'Morph Industries',
      href: 'https://morphindustries.com/',
      width: 110,
      height: 48,
    },

    {
      id: 13,
      src: Skarch,
      alt: 'awaskarch',
      href: 'https://aguaskarch.com/',
      width: 200,
      height: 48,
    },
    {
      id: 14,
      src: Rocketeria,
      alt: 'Baja Aeroespace',
      href: 'https://www.facebook.com/p/La-Rocketeria-100083298129758/?paipv=0&eav=AfZVUckH0D68giYeruZUKnMywQvk0Mq6hThAo5olWSyzIisJkLbBoDjTicEDlwGym2c&_rdr',
      width: 130,
      height: 48,
    },

    {
      id: 15,
      src: Muebles,
      alt: 'Muebles y decoracion',
      href: 'https://www.facebook.com/sm.mueblesydecoracion',
      width: 180,
      height: 48,
    },
    {
      id: 16,
      src: INBEST,
      alt: 'INBEST',
      href: 'https://www.inbest.cloud',
      width: 180,
      height: 100,
    },
    {
      id: 17,
      src: UDEG,
      alt: 'udeg',
      href: 'https://www.udg.mx/es',
      width: 180,
      height: 100,
    },
  ]
  return (
    <div className="bg-black pt-24 sm:pt-32 justify-center" id="patrocinadores">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="text-3xl font-bold tracking-tight sm:text-4xl text-white text-center">
            <span style={{ color: '#3AAAE1' }}>Una historia construida junto a nuestros patrocinadores</span>
          </h1>
          <h2 className="text-xl text-white text-center mt-8">
            En ENMICE estamos emocionados de trabajar con empresas comprometidas con la ciencia, tecnología y educación,
            específicamente en materia espacial y el talento joven. Agradecidos por el apoyo y la confianza, hoy son más de 15 los
            patrocinadores que encuentran en ENMICE la posibilidad de impulsar e interactuar con la nueva generación espacial Mexicana.
          </h2>
        </div>
      </div>
      <div className='w-full max-md:px-0 max-sm:px-0  max-lg:px-0 grid grid-cols-3 grid-flow-row py-10 max-lg:grid-cols-2 max-md:grid-cols-1 bg-black px-52 max-xl:p-10'>
        {patron.map((index) => (
          <a href={index.href} target="_blank" className='flex m-auto h-40 w-[350px]  align-middle max-lg:w-[370px]'>
            <img
              className='hover:scale-125 ease-in duration-300 m-auto'
              src={index.src}
              alt={index.alt}
              width={index.width}
              height={index.height}
            />
          </a>
        ))}
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/*

            <div className="mx-auto mt-10 grid grid-cols-3  items-start gap-x-8 gap-y-10 justify-center sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
              <a href="https://www.steren.com.mx">
                <img
                    className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                    src={Steren}
                    alt="Transistor"
                    width={158}
                    height={48}
                  />
              </a>
              <a href="https://www.udg.mx">
                  <img
                    className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                    src={UDEG}
                    alt="Reform"
                    width={158}
                    height={48}
                  />

              </a>
              <a href="https://www.grupossc.com">
                  <img
                    className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                    src={SSC}
                    alt="Tuple"
                    width={158}
                    height={48}
                  />
              </a>
              <a href="https://www.ansys.com">
                  <img
                    className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                    src={Ans}
                    alt="SavvyCal"
                    width={158}
                    height={48}
                  />
              </a>
            </div>

            <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 justify-center sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
            <a href="https://redcola.mx">
                    <img
                        className="col-span-8 max-h-40 w-full object-contain object-left lg:col-span-1"
                        src={RedCola3}
                        alt="Transistor"
                        width={110}
                        height={40}
                      />
            </a>
            <a href="https://bid360.mx">
                  <img
                      className="col-span-8 max-h-50 w-full object-contain object-left lg:col-span-1"
                      src={BID}
                      alt="Reform"
                      width={200}
                      height={100}
                    />
            </a>
            <a href="https://www.inbest.cloud">
                  <img
                      className="col-span-8 max-h-50 w-full object-contain object-left lg:col-span-1"
                      src={Best2}
                      alt="Tuple"
                      width={316}
                      height={96}
                    />
            </a>
            </div>


            <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
            <a href="https://www.agacentro.com">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Sidral}
                        alt="Transistor"
                        width={158}
                        height={48}
                      />
            </a>
            <a href="https://aguaskarch.com">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Agua}
                        alt="Reform"
                        width={158}
                        height={48}
                      />
            </a>
            <a href="https://craftaviacenter.com">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Craft}
                        alt="Tuple"
                        width={158}
                        height={48}
                      />
            </a>
            </div>


            <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
            <a href="https://www.herox.com">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Hero}
                        alt="Transistor"
                        width={158}
                        height={48}
                      />
            </a>
            <a href="https://www.facebook.com/oleanadvisors/">
                    <img
                        className="col-span-8 max-h-40 w-full object-contain object-left lg:col-span-1"
                        src={OLEAN}
                        alt="Reform"
                        width={150}
                        height={40}
                      />
            </a>
            <a href="https://www.eraaviacion.com">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Era}
                        alt="Tuple"
                        width={165}
                        height={55}
                      />
            </a>
            </div>


            <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
            <a href="https://www.facebook.com/sm.mueblesydecoracion/?locale=es_LA">
                      <img
                          className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                          src={Muebles}
                          alt="Transistor"
                          width={158}
                          height={48}
                        />
            </a>
            <a href="*">
                    <img
                        className="col-span-8 max-h-48 w-full object-contain object-left lg:col-span-1"
                        src={Hadron}
                        alt="Reform"
                        width={158}
                        height={48}
                      />
            </a>
            
            </div>
            */}
      </div>

    </div>
  )
}

/*
 
*/ 