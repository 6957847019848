import AnimatedNumber from "animated-number-react";
import Tecno2 from 'assets/img/Tecno2.jpeg'

const stats = [
  { id: 1, name: 'LANZAMIENTOS', value: '+30', estilo: "flex flex-col p-10", backgroundColor: "#00000099" },
  { id: 2, name: 'ASISTENTES', value: '+1000', estilo: "flex flex-col bg-yellow-600/60 p-10", backgroundColor: "#EE9F24D3" },
  { id: 3, name: 'UNIVERSIDADES', value: '+15', estilo: "flex flex-col bg-gray-950/70 p-10", backgroundColor: "#00000099" },
  { id: 4, name: 'COLABORADORES', value: '+12', estilo: "flex flex-col bg-gray-950/70 p-10", backgroundColor: "#00000099" },
  { id: 5, name: 'EQUIPOS', value: '+35', estilo: "flex flex-col p-10", backgroundColor: "#3AAAE1D3" },
  { id: 6, name: 'PATROCINADORES', value: '+15', estilo: "flex flex-col bg-gray-950/70 p-10", backgroundColor: "#00000099" },
  { id: 7, name: 'ESTADOS DE MÉXICO', value: '+10', estilo: "flex flex-col bg-gray-950/70 p-10", backgroundColor: "#00000099" },
  { id: 8, name: 'INVITADOS', value: '+30', estilo: "flex flex-col bg-rose-700/60 p-10", backgroundColor: "#DB0C81D3" },
  { id: 9, name: 'CERTIFICACIONES', value: '+10', estilo: "flex flex-col bg-gray-950/70 p-10", backgroundColor: "#00000099" },

]

export default function Example() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <main className="relative isolate min-h-full">
        <img
          src={Tecno2}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
        />
        <div className="relative isolate py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-6xl">
                  ENMICE EN NÚMEROS
                </h2>
                <p className="mt-4 text-lg leading-8 text-white">

                </p>
              </div>
              <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-1xl text-center sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((stat) => (
                  <div key={stat.id} className={stat.estilo} style={{ background: stat.backgroundColor }}>
                    <dt className="text-3xl font-semibold leading-6 text-white">{stat.name}</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white text-6xl">+<AnimatedNumber value={stat.value}
                      formatValue={n => n.toFixed(0)} delay={1000} duration={2000} /></dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
/*
<div className="inline-flex rounded-md shadow">
          <a
            href="../Registro"sm:text-center
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
          >
            Registrate.
          </a>
        </div>
        <div className="ml-3 inline-flex rounded-md shadow">
          <a
            href="../Quienes"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50"
          >
            Conocenos.
          </a>
        </div>

        <span className="block text-indigo-600">Iniciar Sesion o registrate..</span>
*/