import React from 'react';

const App = () => {
  return (
    <div className='bg-black' style={{ maxWidth: '100%', margin: '0 auto', padding: '20px' }}>
      <h1 className=" text-3xl font-bold tracking-tight text-white sm:text-4xl" style={{ textAlign: 'center', color: '#3AAAE1' }}>SEDES <span style={{ color: '#EE9F24' }}> 2023</span></h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: '1', marginRight: '20px' }}>
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white" style={{ textAlign: 'center', color: '#3AAAE1' }}>Sede principal</h2>
          <h3 className="text-2xl font-bold leading-10 tracking-tight text-white" style={{ textAlign: 'center', color: '#EE9F24' }}>Expo Mundo Imperial </h3>
          <h4 style={{ textAlign: 'center', color: 'white' }}>Boulevard Barra Vieja, Plan de Los Amates No. 3, Colonia, 39931 Acapulco de Juárez, Gro.</h4>
          <br />
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <iframe
              title="Mapa 1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.1476716066823!2d-99.77174370000002!3d16.769327099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ca5c7efaa3daed%3A0x4689ba4820d505!2sExpo%20de%20Mundo%20Imperial!5e0!3m2!1ses-419!2smx!4v1688683251938!5m2!1ses-419!2smx"
              width="100%"
              height="300"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

          </div>
        </div>
        <div style={{ flex: '1', marginLeft: '20px' }}>
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white" style={{ textAlign: 'center', color: '#3AAAE1' }}>Sede lanzamientos</h2>
          <h3 className="text-2xl font-bold leading-10 tracking-tight text-white" style={{ textAlign: 'center', color: '#EE9F24' }}>Playa El Dorado</h3>
          <h4 style={{ textAlign: 'center', color: 'white' }}>San Marcos, Gro.</h4>
          <br />
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <iframe
              title="Mapa 2"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3822.1747027027827!2d-99.3463333!3d16.6681389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDQwJzA1LjMiTiA5OcKwMjAnNDYuOCJX!5e0!3m2!1ses-419!2smx!4v1688684675363!5m2!1ses-419!2smx"
              width="100%"
              height="300"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

          </div>
        </div>
      </div>
    </div>
  );
};

export default App;

