import Seccion from "componets/Education/Seccion"
import Footer from "componets/navigation/Footer"
import Navbar from "componets/navigation/Navbar"
import Layout from "hocs/layouts/Layout"

function Patrocinador(){
    return(
        <Layout>
            <Navbar/>
            <div className="pt-16">
                <Seccion/>
            </div>
            <Footer/>
        </Layout>
    )

}
export default Patrocinador