

import Victor1 from 'assets/img/Victor1.jpg'
import Bryan2 from 'assets/img/Bryan2.jpg'
import Fernando from 'assets/img/fernando.jpg'


const people = [
  {
    name: <span style={{ color: '#3AAAE1' }}>Bryan Ham</span>,
    role: 'Científico de datos',
    imageUrl: Bryan2,

  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Víctor Carreño</span>,
    role: 'Arquitecto de software',
    imageUrl: Victor1,

  },
  {
    name: <span style={{ color: '#3AAAE1' }}>Fernando Sánchez</span>,
    role: 'Analista de datos',
    imageUrl: Fernando,

  },

  // More people...
]

export default function Example() {
  return (
    <div className="bg-black py-24 sm:py-32 " >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="space-y-12 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center "><span style={{ color: '#EE9F24' }}>AGRADECIMIENTOS</span></h2>
          <p className="text-xl text-white text-center">
            Agradecemos la colaboración de los integrantes de la Agencia Espacial Mexicana por participar en el evento de <span style={{ fontWeight: '780' }}>ENMICE 2023</span>
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4  "
        >
          {people.map((person) => (
            <li key={person.name}>
              <img className="aspect-[14/13] w-full rounded-1xl object-cover " src={person.imageUrl} alt="" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white" style={{ fontSize: '24px' }}>{person.name}</h3>
              <p className="text-base leading-7 text-white">{person.role}</p>
              <p className="text-sm leading-6 text-white">{person.location}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}