import { React, useState, useEffect, useRef } from 'react';
import ReactDOM from "react-dom/client";
import 'styles/index.css'

import Real from 'assets/img/Real.png';
import Steren from 'assets/img/Steren.png';
import Hero from 'assets/img/Hero.png';
import { Typewriter } from 'react-simple-typewriter'
//Fotos de patrocinadores
import COMAEM from 'assets/img/COMAEM.png'
import FEMIA from 'assets/img/FEMIA.png'
import AFAC2 from 'assets/img/AFAC2.jpg'
import Marista from 'assets/img/Marista.png'
import UABC from 'assets/img/UABC.png'
import Hacia from 'assets/img/Hacia.png'
import Baja from 'assets/img/Baja.png'
import BID from 'assets/img/BID.png'
import Sayula from 'assets/img/Sayula.png'
import Ray from 'assets/img/Ray.png'
import Unisec from 'assets/img/Unisec.jpg'
import Mexico from 'assets/img/Mexico.png'
import MiNDPRO from 'assets/img/MiNDPRO.png'
//Fotos del carrusel de eventos pasados
import pasado1 from 'assets/img/2022_1.jpg'
import pasado2 from 'assets/img/2022_2.jpg'
import pasado3 from 'assets/img/2022_3.jpg'
import pasado4 from 'assets/img/2022_4.jpg'
import pasado5 from 'assets/img/2022_5.jpg'
import pasado6 from 'assets/img/2022_6.jpg'
import pasado7 from 'assets/img/2022_7.jpg'
import pasado8 from 'assets/img/2022_8.jpg'
import pasado9 from 'assets/img/2022_9.jpg'
import pasado10 from 'assets/img/2022_10.jpg'
import pasado11 from 'assets/img/2022_11.jpg'
import pasado12 from 'assets/img/2022_12.jpg'
import pasado13 from 'assets/img/2022_13.jpg'
import pasado14 from 'assets/img/2022_14.jpg'
import pasado15 from 'assets/img/2022_15.jpg'
import pasado16 from 'assets/img/2022_16.jpg'
import pasado17 from 'assets/img/2022_17.jpg'
import pasado18 from 'assets/img/2022_18.jpg'
import pasado19 from 'assets/img/2022_19.jpg'
import pasado20 from 'assets/img/2022_20.jpg'
import pasado21 from 'assets/img/2022_21.jpg'
import pasado22 from 'assets/img/2022_22.jpg'
import pasado23 from 'assets/img/2022_23.jpg'
import pasado24 from 'assets/img/2022_24.jpg'
import pasado25 from 'assets/img/2022_25.jpg'

import Carousel from '@itseasy21/react-elastic-carousel';



const YouTube = () => {
  //fotos del carrusel
  const pasado = [
    {
      id: 0,
      src: pasado1,
      alt: "Foto de eventos pasados",
    },
    {
      id: 1,
      src: pasado2,
      alt: "Foto de eventos pasados",
    },
    {
      id: 2,
      src: pasado3,
      alt: "Foto de eventos pasados",
    },

    {
      id: 3,
      src: pasado4,
      alt: "Foto de eventos pasados",
    },
    {
      id: 4,
      src: pasado5,
      alt: "Foto de eventos pasados",
    },
    {
      id: 5,
      src: pasado6,
      alt: "Foto de eventos pasados",
    },
    {
      id: 6,
      src: pasado7,
      alt: "Foto de eventos pasados",
    },
    {
      id: 7,
      src: pasado8,
      alt: "Foto de eventos pasados",
    },
    {
      id: 8,
      src: pasado9,
      alt: "Foto de eventos pasados",
    },
    {
      id: 9,
      src: pasado10,
      alt: "Foto de eventos pasados",
    },
    {
      id: 10,
      src: pasado11,
      alt: "Foto de eventos pasados",
    },
    {
      id: 11,
      src: pasado12,
      alt: "Foto de eventos pasados",
    },
    {
      id: 12,
      src: pasado13,
      alt: "Foto de eventos pasados",
    },
    {
      id: 13,
      src: pasado14,
      alt: "Foto de eventos pasados",
    },
    {
      id: 14,
      src: pasado15,
      alt: "Foto de eventos pasados",
    },
    {
      id: 15,
      src: pasado16,
      alt: "Foto de eventos pasados",
    },
    {
      id: 16,
      src: pasado17,
      alt: "Foto de eventos pasados",
    },
    {
      id: 17,
      src: pasado18,
      alt: "Foto de eventos pasados",
    },
    {
      id: 18,
      src: pasado19,
      alt: "Foto de eventos pasados",
    },
    {
      id: 19,
      src: pasado20,
      alt: "Foto de eventos pasados",
    },
    {
      id: 20,
      src: pasado21,
      alt: "Foto de eventos pasados",
    },
    {
      id: 21,
      src: pasado22,
      alt: "Foto de eventos pasados",
    },
    {
      id: 22,
      src: pasado23,
      alt: "Foto de eventos pasados",
    },
    {
      id: 23,
      src: pasado24,
      alt: "Foto de eventos pasados",
    },
    {
      id: 24,
      src: pasado25,
      alt: "Foto de eventos pasados",
    },
  ]
  //Información para que el carrusel sea responsive 
  const breakPoint = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 480, itemsToShow: 2, itemsToScroll: 2 },
    { width: 750, itemsToShow: 3, itemsToScroll: 3 },
  ]
  //Codigo del carrusel infinito con clicks
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(pasado.length);
    }
  };

  //Codigo del carrusel infinito automatico
  const itemsPerPage = 1
  const totalPages = Math.ceil(pasado.length / itemsPerPage)
  let resetTimeout;

  //Codigo para hacer grande una imagen seleccionada del carrusel
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const handleClick = (indexId) => {
    setSelectedPhoto(indexId);
  };
  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };



  return (
    <div className='bg-black px-6 p-24 sm:py-30 lg:px-8 flex justify-center '>
      <div className='max-lg:flex-col w-full'>
        {/*Foto seleccionada del carrusel*/}
        {selectedPhoto && (
          <div className="w-full bg-black">
            <div className="w-1/3 m-auto max-lg:w-11/12">  {/* Modifica el tamaño de la foto*/}
              <img src={selectedPhoto.src} alt="Foto" />
              <button className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white text-center shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 mx-auto my-10 block " onClick={handleCloseModal}>
                Cerrar
              </button>
            </div>
          </div>
        )}
        {/* Carrusel*/}
        <div>
          <div className='w-full flex justify-items-center'>
            <Carousel
              itemsToScroll={3}
              itemsToShow={3}
              pagination={false}
              itemPadding={[0, 10]}
              breakPoints={breakPoint}
              ref={carouselRef}
              onPrevStart={onPrevStart}
              onNextStart={onNextStart}
              disableArrowsOnEnd={false}
            >
              {/* Generador de carrusel que itera el objeto de las fotos */}
              {pasado.map((index) => (
                <div>
                  <img
                    src={index.src}
                    alt={index.alt}
                    key={index.id}
                    className={'flex h-52 m-auto cursor-pointer max-sm:h-36'} //Ancho de las fotos del carrusel
                    onClick={() => handleClick(index)}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div> {/*Div seleccionado*/}
      </div>
    </div>
  )
}

export default YouTube;