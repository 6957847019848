import Header from "componets/About/Header"
import Stats from "componets/About/Stats"
import Team from "componets/About/Team"
import Team2 from "componets/About/Team2"
import Team3 from "componets/About/Team3"
import Test from "componets/About/Test"
import Footer from "componets/navigation/Footer"
import Navbar from "componets/navigation/Navbar"
import Layout from "hocs/layouts/Layout"
import LogoCloud from "componets/home/LogoCloud";

function Acerca(){
    return(
        <Layout>
            <Navbar/>
            <div className="pt-16">
                <Team/>
                <Team3/>
                <Team2/>
            </div>
            <Footer/>
        </Layout>
    )

}
export default Acerca


/*
<Header/>
<Test/>
<LogoCloud/>
*/