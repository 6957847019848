import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaLanguage, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';

const socialMediaData = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/',
    country: '',
    icon: FaFacebook,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/',
    country: '',
    icon: FaTwitter,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/',
    country: '',
    icon: FaInstagram,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/',
    country: '',
    icon: FaLinkedin,
  },
  {
    name: 'Pais: Estados Unidos',
    url: 'https://www.linkedin.com/',
    country: '',
    icon: FaGlobe,
  },
  {
    name: 'Idioma: Ingles',
    url: 'https://www.linkedin.com/',
    country: '',
    icon: FaLanguage,
  },
];

const App = () => {
  const [hoveredIcon, setHoveredIcon] = React.useState(null);

  const listItemStyle = {
    marginBottom: '10px',
  };

  const socialMediaItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    transition: 'transform 0.2s ease',
    backgroundColor: hoveredIcon ? '#f0f0f0' : 'white',
    cursor: 'pointer',
  };

  const socialMediaNameStyle = {
    fontWeight: 'bold',
    marginLeft: '10px',
  };

  const socialMediaCountryStyle = {
    color: '#666',
  };

  const handleMouseEnter = (index) => {
    setHoveredIcon(index);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <div className=" bg-black" style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', backgroundColor: 'black' }}>
      <h1>Social Media List</h1>
      <ul>
        {socialMediaData.map((socialMedia, index) => {
          const Icon = socialMedia.icon;
          const isHovered = hoveredIcon === index;

          return (
            <li
              key={index}
              style={listItemStyle}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <a href={socialMedia.url} target="_blank" rel="noopener noreferrer">
                <div className="social-media-item" style={{ ...socialMediaItemStyle, backgroundColor: isHovered ? '#f0f0f0' : 'white' }}>
                  <Icon size={24} />
                  <span style={socialMediaNameStyle}>{socialMedia.name}</span>
                  <span style={socialMediaCountryStyle}>{socialMedia.country}</span>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default App;
